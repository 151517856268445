<template>
  <footer class="relative z-10 w-full py-3 mt-32 border-t border-zinc-200">
    <div class="w-full">
      <ol class="flex items-center justify-center">
        <li class="px-3">
          <router-link
            to="/terms/terms_of_customer"
            class="text-sm transition-all duration-300 text-zinc-500 hover:text-primary"
          >
            服務條款
          </router-link>
        </li>
        <li class="px-3">
          <router-link
            to="/terms/site_privacy"
            class="text-sm transition-all duration-300 text-zinc-500 hover:text-primary"
          >
            隱私權政策
          </router-link>
        </li>
        <li class="px-3">
          <router-link
            to="/terms/return_policy"
            class="text-sm transition-all duration-300 text-zinc-500 hover:text-primary"
          >
            退換貨政策
          </router-link>
        </li>
      </ol>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
        {
          title: "非會員查詢訂單",
          sub_title: "OrderSearch",
          link: "/order_search/search",
        },
      ],
    };
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
