<template>
  <div
    id="app"
    class="relative z-10 flex flex-col w-full min-h-screen bg-zinc-100"
  >
    <MainLoading />

    <template v-if="all_data_loaded">
      <MainDialog />
      <div class="relative z-20 flex-1">
        <router-view @set-meta="SetMetaData" @load-image="LoadImage" />
      </div>

      <!-- <a
        target="_blank"
        :href="$GetColumn('company_messenger')"
        class="fixed z-30 flex items-center px-4 py-1 text-sm font-bold text-white transition-all duration-300 border-2 border-white rounded-full shadow-lg md:px-6 md:py-2 hover:md:bg-white hover:md:text-primary md:bottom-10 bottom-5 right-5 md:right-10 bg-primary"
      >
        <i class="mr-2 text-lg md:text-xl icon-line"></i>線上客服
      </a> -->
      <MainFooter />
    </template>
    <div
      class="fixed top-0 bottom-0 left-0 right-0 z-0 bg-repeat main_fixed_bg"
    ></div>
  </div>
</template>

<script>
import MainDialog from "@/components/MainDialog.vue";
import MainLoading from "@/components/MainLoading.vue";
import MainFooter from "./components/MainFooter.vue";
import { ImageLoader } from "@/gsap/ImageLoader";
import { GetMetaData } from "@/common/meta";
// import { APIViewPage } from "@/common/pixel_methods";
import { setLocalStorage, delLocalStorage } from "@/common/cookie";
export default {
  name: "App",
  components: {
    MainDialog,
    MainLoading,
    MainFooter,
  },
  data() {
    return {
      image_loader: null,
      menu_status: false,
      meta_data: null,
    };
  },
  methods: {
    LoadImage() {
      // 確認頁面圖片讀取狀況
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    SetMetaData(title, content, image) {
      this.meta_data = GetMetaData(title, content, image);
    },
  },
  watch: {
    all_data_loaded() {
      if (this.all_data_loaded) {
        this.$store.dispatch("shopcart_module/GetShopCart");
      }
      // if (this.all_data_loaded && this.first_time_load) {
      //   this.first_time_load = false;
      // }
    },
    // $route() {
    //   APIViewPage();
    // },
  },
  computed: {
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
  },
  mounted() {
    this.image_loader = new ImageLoader();
    window.addEventListener("resize", () => {
      this.menu_status = false;
    });

    const currentUrl = new URL(window.location.href);
    const urlParams = currentUrl.searchParams;

    if (urlParams.has("order_memo")) {
      setLocalStorage("order_memo", urlParams.get("order_memo"));
    } else {
      delLocalStorage("order_memo");
    }
  },
  created() {
    !this.all_data_loaded ? this.$store.dispatch("getAllData") : "";
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>

<style src="@/assets/css/output.css"></style>
