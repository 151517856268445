import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首頁",
    redirect: "/error_page",
  },
  {
    path: "/:event_id/order_create/:id",
    name: "訂單完成",
    component: () => import("../views/TradeFinishView.vue"),
  },
  {
    path: "/terms/:id",
    name: "條款頁面",
    component: () => import("../views/TermsView.vue"),
  },
  {
    path: "/error_page",
    name: "錯誤頁面",
    component: () => import("../views/ErrorPageView.vue"),
  },
  {
    path: "/:id",
    name: "活動頁面",
    component: () => import("../views/EventPageView.vue"),
  },
  {
    path: "*",
    redirect: "/error_page",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 換頁滾動至最上面
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (hasQueryParams(from) && from.query.order_memo) {
    if (to.query && to.query.order_memo) {
      next();
    } else {
      let new_query = null;
      new_query = to.query ? to.query : from.query;
      if (!to.order_memo) {
        new_query.order_memo = from.query.order_memo;
      }
      next({ name: to.name, query: new_query, params: to.params });
    }
  } else {
    next();
  }
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
